import React from 'react'
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';

const NumberFormatCustom = (props)  => {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        isNumericString
        suffix="%"
      />
    );
  }

  const PercentageField = (props) => (
    <TextField
      value={props.stateValue}
      onChange={props.handleChange}
      name={props.name}
      id="formatted-numberformat-input"
      InputProps={{
          inputComponent: NumberFormatCustom,
      }}
      variant="outlined"
      />
    )

  export default PercentageField;