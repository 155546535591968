import React, { Component } from 'react';
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {getlistdata} from '../actions/index'
import FormMenubar from '../components/formmenubar'
import FloatingActionButton from '../components/floatingbtn'
import {Link} from 'react-router-dom'
import DataTable from '../components/datatable';

export class GASBList extends Component {

  state = {
    listdt:false,
    status:"waiting",
    display:false
  }

  componentDidMount() {
    this.props.getlistdata();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.status !== this.props.listdata.listData.status) {
      // console.log(this.props.listdata.listData.status);
      if(this.props.listdata.listData.status === "received"){
          return this.setState({status:"received", display:true})
      }
    }
  }

  
  render() {
    return (
      <div>
        <div className="gs-bg-cont-rpt">
          <div className="gs-bg-rpt-menu">
            <FormMenubar/>
          </div>
          <div className="gs-bg-rpt-btn">
            <Link to="/gasb-form">
              <FloatingActionButton fblabel="Add New"/>
            </Link>
          </div>
          <div className="gs-bg-rpt-table">
            {this.state.display && <DataTable/>}
          </div>
        </div>
      </div>
  )}
}

const mapDispatchToProps = (dispatch) => {
  return {...bindActionCreators({getlistdata}, dispatch)};
 }

const mapStateToProps = state => ({
  listdata:state
});

const GASBReports = connect(mapStateToProps, mapDispatchToProps)(GASBList);

export default GASBReports;