import React, { useState } from "react";
import {connect} from 'react-redux'
import MUIDataTable, {ExpandButton} from "mui-datatables";
import {Table,TableBody, TableHead} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import { Typography } from '@material-ui/core';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: '100%',
  }
});


/* Google Storage - Update var in prod */
const GoogleBucket = 'gasb-pdf';
// const GoogleBucket = 'gasb-pdf-dev'; 

function ListTableElement({statetree}) {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [responsive, setResponsive] = useState("standard");
  const [tableBodyHeight, setTableBodyHeight] = React.useState("100%");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = React.useState("100%");

  let arr = statetree.listData.data[0];
  var dataset = arr.slice(0,arr.length);
  // console.log(dataset);

  const columns = [
    { name: 'created_date', label: 'CreatedDate', minWidth: 50},
    {
      name: "pin_number",
      label: "Pin",
    },
    {
      name: "pdf_file",
      label: "PDF",
      options:{
        filter:false,
        sort:false,
        empty:true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            /*    Google Cloud Storage - update bucket URL var with prod var during deployment  */
            <button variant="outlined" color="primary" onClick={() => window.open(`https://storage.googleapis.com/${GoogleBucket}/${dataset[dataIndex].pin_number}-GASBForm.pdf`)}>
              Download PDF
            </button>
          );
        }
      }
    },
    {
      name: "project_number",
      label: "Project Number"
    },
    {
      name: "project_manager",
      label: "Project Manager"
    },
    {
      name: "resident_engineer",
      label: "Resident Engineer"
    },
    {
      name: "contractor",
      label: "Contractor"
    },
    { name: 'substantially_complete', label: 'Completion', minWidth: 50},
    { name: 'replace_pavement', label: 'Replace Pavement', minWidth: 50},
    { name: 'pos_beg_mp', label: 'Pos Beg MP', minWidth: 50},
    { name: 'pos_end_mp', label: 'Pos End MP', minWidth: 50},
    { name: 'neg_beg_mp', label: 'Neg Beg MP', minWidth: 50},
    { name: 'neg_end_mp', label: 'Neg End MP', minWidth: 50},
    { name: 'struct_span_20', label: '20Ft', minWidth: 50},
    { 
      name: 'structures', 
      options:  {
        display:false
      }
    },
    { name: 'email', label: 'email', minWidth: 50}
  ];

  const options = {
    fixedHeader: true,
    filter: false,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [5,10,20,50,100],
    responsive:setResponsive,
    selectableRows:"none",
    jumpToPage:true,
    tableBodyHeight,
    tableBodyMaxHeight,
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    isRowExpandable: (dataIndex, expandedRows) => {
      if (dataIndex === 3 || dataIndex === 4) return false;

      // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
      if (expandedRows.data.length > 4 && expandedRows.data.filter(d => d.dataIndex === dataIndex).length === 0) return false;
      return true;
    },
    rowsExpanded: [0, 1],
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      {console.log('rowData:',rowData)}
      return (
        // <TableRow>
        //   <TableCell colSpan={colSpan}>
        //     {console.log('rowData:',rowData)}
        //     {rowData[14].map((e,i) =>
        //           <li>{JSON.stringify(rowData[14][i])}</li>
        //       )}
        //   </TableCell>
        // </TableRow>
        <tr>
        <td colSpan={6}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell >Structure #</TableCell>
                <TableCell align="left">Structure Cost</TableCell>
                <TableCell align="left">Replaced Structure</TableCell>
                <TableCell align="left">New or Replacement</TableCell>
                <TableCell align="left">Was capacity/efficiency increased?</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData[14].map(row => (
                <TableRow key={row.structure_id}>
                  <TableCell align="left">{row.structure}</TableCell>
                  <TableCell align="left">{row.cost}</TableCell>
                  <TableCell align="left">{row.replacement}</TableCell>
                  <TableCell align="left">{row.new}</TableCell>
                  <TableCell align="left">{row.efficiency}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </td>
        </tr>
      );
    },
    // onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => console.log(curExpanded, allExpanded, rowsExpanded)
  };


  const components = {
    ExpandButton: function(props) {
      if (props.dataIndex === 3 || props.dataIndex === 4) return <div style={{width:'24px'}} />;
      return <ExpandButton {...props} />;
    }
  };

  return (
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
        <MUIDataTable
          title={
            <Typography variant="h6">
              GASB List
            </Typography>
          }
          data={dataset}
          columns={columns}
          options={options}
          components={components}
          />

        </TableContainer>
      </Paper>
  );
}

const mapStateToProps = state => ({
    statetree:state
  });
  
  
  const DataTable = connect(mapStateToProps)(ListTableElement);
  
  export default DataTable;
  