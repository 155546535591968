import React from 'react';

function HoverButton(props) {
        return (
                <button className="banner-btn" type={props.type} value={props.value} onClick={props.onClick}>
                        <span>{props.hbutton}</span>
                </button>
        );
    }

export default HoverButton;