import React, { Component } from 'react'
import logo from '../asset/UDOT_logo.png'


export default class HeaderComponent extends Component {
    render() {
        return (
            <header className="App-header">
                <div className="header-left"><img src={logo} className="App-logo" alt="logo" /></div>
                <div className="header-right">GASB (Governmental Accounting Standards Board) Form</div>
            </header>
        )
    }
}
