import {combineReducers} from 'redux';
import listData from './getlistdata';
import pdfData from './getpdfdata';
import auth from './authentication'
import formStatus from './formstatus'


const rootReducer = combineReducers({
    pdfData,
    listData,
    auth,
    formStatus
});

export default rootReducer;