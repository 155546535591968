import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid, GridToolbar} from '@material-ui/data-grid';
import {updatestructuredata} from '../actions/index';
import {bindActionCreators} from 'redux';

const columns = [
  { field: 'id', headerName: 'Id',  width: 90, align: 'left',editable: false,},
  { field: 'pin_number', headerName: 'Pin Number',  width: 175, align: 'left',editable: false,},
  { field: 'project_number',headerName: 'Project Number', width: 175, align: 'left',editable: false,},
  { field: 'project_manager', headerName: 'Project Manager', width: 175, align: 'left',editable: false,},
  { field: 'resident_engineer', headerName: 'Resident Engineer',  width: 175, align: 'left',editable: false,},
  { field: 'contractor',  headerName: 'Contractor', width: 175, align: 'left' ,editable: false},
  { field: 'structure',  width: 150, align: 'left' ,editable: true},
  { field: 'cost', width: 150, align: 'left' ,editable: true},
  { field: 'replacement',  width: 150, align: 'left' ,editable: true},
  { field: 'new', width: 150, align: 'left' ,editable: true},
  { field: 'efficiency',  width: 150, align: 'left' ,editable: true}
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: '100%',
  }
});

/* Structure list - update url for prod */
// const ROOT_URL = `https://gasb.dev.utah.gov`
const ROOT_URL = `https://gasb.udot.utah.gov`

function ListStructuresElement(props) {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [structures, setStructures] = useState([]);

    useEffect(() => {
      fetch(`${ROOT_URL}/api/gasb/gasb-structures`)
        .then(res => res.json())
        .then(
          (result) => {
            console.log(result)
            setIsLoaded(true);
            setStructures(result);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
    }, [])

    const handleEditCellChangeCommitted = async (data) => {
      let jti = localStorage.getItem('jti');
      let updateStructureData = {
        "id":data.id,
        "field":data.field,
        "value":data.props.value,
        "jti":jti
      }
      updatestructuredata(updateStructureData);
    };

    return (
        <div style={{ height: '80vh', width: '100%' }}>
            <DataGrid 
                rows={structures} 
                columns={columns} 
                pageSize={25} 
                onEditCellChangeCommitted={handleEditCellChangeCommitted}
                components={{
                  Toolbar: GridToolbar,
                }}              
            />
        </div>
    );
}


const mapDispatchToProps = (dispatch) => {
    return {...bindActionCreators({updatestructuredata}, dispatch)};
   }

   const mapStateToProps = state => ({
    structuredata:state
  });

const StructureTable = connect(mapStateToProps,mapDispatchToProps)(ListStructuresElement);

export default StructureTable;