import React, { Component } from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {getlistdata} from '../actions/index'
import FormMenubar from '../components/formmenubar'
import GAuthButton from '../containers/authbutton'


class AdminView extends Component {

    state = {
        listdt:false,
        status:"waiting",
        display:false
      }
    
    componentDidMount() {
    this.props.getlistdata();
    }

    componentDidUpdate(prevProps, prevState) {
    if (prevState.status !== this.props.listdata.listData.status) {
        // console.log(this.props.listdata.listData.status);
        if(this.props.listdata.listData.status === "received"){
            return this.setState({status:"received", display:true})
        }
    }
    }


    render() {
        return (
            <div>
                <div className="gs-bg-cont-rpt">
                    <FormMenubar/>
                    <div className="cont-admin">
                        <p className="gs-admin">Admin Screen</p>
                        <GAuthButton/>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {...bindActionCreators({getlistdata}, dispatch)};
   }
  
const mapStateToProps = state => ({
    listdata:state
  });


const Admin = connect(mapStateToProps, mapDispatchToProps)(AdminView);

export default Admin;

