import React, {useEffect, useState} from 'react'
import InputLabel from '@material-ui/core/InputLabel';


const InputLabelComponent = (props) => {
    const [isDesktop, setDesktop] = useState(window.innerWidth > 700);
  
    const updateMedia = () => {
      setDesktop(window.innerWidth > 700);
    };
  
    useEffect(() => {
      window.addEventListener("resize", updateMedia);
      return () => window.removeEventListener("resize", updateMedia);
    });
  
    return (
      <div>
        {isDesktop ? (
            <div className="formfieldlabel">{props.fieldlabel}</div>
        ) : (
            <InputLabel shrink htmlFor="bootstrap-input"> {props.fieldlabel}</InputLabel>
        )}
      </div>
    );
  }

  export default InputLabelComponent
