import React, { Component } from 'react'
import ProjectFormv2 from '../containers/formv2'
import FormMenubar from '../components/formmenubar';
import { Container } from '@material-ui/core';

export class GASBForm extends Component {

  render() {
    return (
      <div className="gs-bg-cont" >
        <div className="gs-bg-form-1">
            <FormMenubar/>
        </div>
        <Container maxWidth="md">
        <div className="gs-bg-form-2">
            <div  className="gs-bg-form-title">GASB (Governmental Accounting Standards Board) Form</div>
            <div className="gs-bg-form-subtitle">(To be used for Capital Projects Only as identified on the GASB Report)</div>
        </div>
        </Container>
        <ProjectFormv2 />
      </div>
  )}
}

export default GASBForm;