import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid, GridToolbar} from '@material-ui/data-grid';
import {updatetabledata} from '../actions/index';
import {bindActionCreators} from 'redux';

const columns = [
  { field: 'pin_number', headerName: 'Pin Number',  width: 175, align: 'left',editable: true, },
  { field: 'project_number',headerName: 'Project Number', width: 175, align: 'left',editable: true,},
  { field: 'project_manager', headerName: 'Project Manager', width: 175, align: 'left',editable: true,},
  { field: 'resident_engineer', headerName: 'Resident Engineer',  width: 175, align: 'left',editable: true,},
  { field: 'contractor',  headerName: 'Contractor', width: 175, align: 'left' ,editable: true},
  { field: 'substantially_complete',  width: 150, align: 'left' ,editable: true},
  { field: 'replace_pavement', width: 150, align: 'left' ,editable: true},
  { field: 'pos_beg_mp',  width: 150, align: 'left' ,editable: true},
  { field: 'pos_end_mp', width: 150, align: 'left' ,editable: true},
  { field: 'neg_beg_mp',  width: 150, align: 'left' ,editable: true},
  { field: 'neg_end_mp',  width: 150, align: 'left' ,editable: true},
  { field: 'struct_span_20',  width: 150, align: 'left' ,editable: true},
  { field: '1st_structure_increased', width: 150, align: 'left' ,editable: true},
  { field: '2nd_structure_increased', width: 150, align: 'left' ,editable: true},
  { field: 'email',  width: 150, align: 'left' ,editable: true},
  { field: 'created_date', width: 150, align: 'left' ,editable: true}
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: '100%',
  }
});

/* Structure list - update url for prod */
// const ROOT_URL = `https://gasb.dev.utah.gov`
const ROOT_URL = `https://gasb.udot.utah.gov`

function ListTableElement(props) {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
 
    useEffect(() => {
      fetch(`${ROOT_URL}/api/gasb/gasb-list`)
        .then(res => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setItems(result);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
    }, [])

    const handleEditCellChangeCommitted = async (data) => {
      let jti = localStorage.getItem('jti');
      let updateListData = {
        "id":data.id,
        "field":data.field,
        "value":data.props.value,
        "jti":jti
      }
      updatetabledata(updateListData);
    };

    return (
        <div style={{ height: '80vh', width: '100%' }}>
            <DataGrid 
                rows={items} 
                columns={columns} 
                pageSize={25} 
                onEditCellChangeCommitted={handleEditCellChangeCommitted}
                components={{
                  Toolbar: GridToolbar,
                }}              
            />
        </div>
    );
}


const mapDispatchToProps = (dispatch) => {
    return {...bindActionCreators({updatetabledata}, dispatch)};
   }

   const mapStateToProps = state => ({
    listdata:state
  });

const ListTable = connect(mapStateToProps,mapDispatchToProps)(ListTableElement);

export default ListTable;