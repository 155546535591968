
import React, { Component } from 'react'
import { GoogleLogin,GoogleLogout } from 'react-google-login'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {authadminlogin,authadminlogout} from '../actions/index'
import {withRouter} from 'react-router-dom'
import ListTable from '../components/listtable'
import Tabs from '@material-ui/core/Tabs'
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import StructureTable from '../components/structuretable'


/* Google URL - update prod var */
// const ROOT_URL = `${process.env.REACT_APP_API_URL}`
const ROOT_URL = `https://gasb.udot.utah.gov`
// const ROOT_URL = `https://gasb.dev.utah.gov`

/* Google CLIENT ID - update prod var */
const CLIENT_ID = '162131396791-qnb6lisq7vegf5sk677mggn15uopntd1.apps.googleusercontent.com';
// const CLIENT_ID = `386082074571-6fhah9298q2u4segc91e1uor3f1694ds.apps.googleusercontent.com`

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
  }

function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

class GAuthButtonComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated:false,
            value:0
        };
        
        this.handleLogin = this.handleLogin.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    
    componentDidMount(){
        let tokenExists = localStorage.getItem("jti");
        if (tokenExists !== 'undefined' &&  tokenExists !== null) {
            tokenExists.length === 40 ? this.setState({isAuthenticated:true}) : this.setState({isAuthenticated:false})
        } else {
            return;
        }
    }

    componentDidUpdate(prevProps) {
        console.log('prevProps:',prevProps)
        if (this.props.isAuthenticated!== prevProps.isAuthenticated) {
            this.setState({isAuthenticated:true, accessToken:prevProps.accessToken});
            return true;
        } else {
          return false;
        }
      }

    handleChange = (event, newValue) => {
        this.setState({value:newValue});
    };

    handleLogin = async googleData => {
        console.log('gauth data:',googleData);
        this.props.authadminlogin(googleData);
    }

    handleLogout = async googleData => {
        this.props.authadminlogout({"accessToken":this.state.accessToken});
        window.location.reload();
        return false;
    }

render() {
    let isLoggedIn = this.state.isAuthenticated;
    let logbutton;
    if(isLoggedIn==true) {
        logbutton =    <div>
                        <GoogleLogout
                            clientId={CLIENT_ID}
                            buttonText="Logout"
                            onLogoutSuccess={this.handleLogout}
                            redirectUri={ROOT_URL}
                            className="logout-btn"
                            >
                        </GoogleLogout>
                        <Tabs
                            orientation="horizontal"
                            variant="scrollable"
                            value={this.state.value}
                            onChange={this.handleChange}
                            aria-label="GASB Admin Tables"
                        >
                            <Tab label="GASB List" {...a11yProps(0)} />
                            <Tab label="GASB Structures" {...a11yProps(1)} />
                        </Tabs>
                        <TabPanel value={this.state.value} index={0}>
                            <ListTable /> 
                        </TabPanel>
                        <TabPanel value={this.state.value} index={1}>
                            <StructureTable />
                        </TabPanel>
                        
                        
                        </div>
    } else {
        logbutton =    <GoogleLogin
                            clientId={CLIENT_ID}
                            buttonText="Login To Edit Table"
                            onSuccess={this.handleLogin}
                            onFailure={this.handleLogin}
                            cookiePolicy={'single_host_origin'}
                        />;
        }
    return (
        <div is className="login-button">   
            {logbutton}
        </div>
    )
}
}

const mapDispatchToProps = (dispatch) => {
    return {...bindActionCreators({authadminlogin,authadminlogout}, dispatch)};
   }

const mapStateToProps = (state) => {
    const { isAuthenticated, accessToken, error } = state.auth
    return {
        isAuthenticated,
        accessToken,
        error
    }
}
  
const GAuthButton= withRouter(connect(mapStateToProps, mapDispatchToProps)(GAuthButtonComponent));

export default GAuthButton;
