import { Switch, Route } from "react-router-dom"
import GASBForm from './views/gasbform'
import GASBPDF from './views/gasbpdf'
import GASBReports from './views/gasbreports'
import Home from './views/home'
import Admin from './views/gasbadmin';
import HeaderComponent from './components/header'
import './App.scss'

function App() {
  return (
    <div className="App">
      <header><HeaderComponent/></header>
      <Switch>
        <Route exact path="/" component={Home}></Route>
        <Route exact path="/gasb-form" component={GASBForm}></Route>
        <Route exact path="/gasb-pdf" component={GASBPDF}></Route>
        <Route exact path="/gasb-reports" component={GASBReports}></Route>
        <Route exact path="/gasb-admin" component={Admin}></Route>
      </Switch>
    </div>
  );
}

export default App;