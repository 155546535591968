import React from 'react';

function CancelButton(props) {
        return (
                <button className="can-banner-btn">
                        <span>{props.cbutton}</span>
                </button>
        );
    }

export default CancelButton;