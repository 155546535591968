  
import {LOGIN_REQUEST ,LOGIN_SUCCESS, LOGOUT_REQUEST, LOGOUT_SUCCESS} from '../actions/index';

export default function auth(state = {
    isAuthenticated: false,
    isFetching: false,
    accessToken:"",
    error: null
  }, action) {
        switch (action.type) {
            case LOGIN_REQUEST:
                return {...state, isFetching: true, error: null}
            case LOGIN_SUCCESS:
                console.log(action.payload);
                let addAdminToken = localStorage.setItem('adminValidated',action.payload.data.adminValidated);
                let addJTIToken = localStorage.setItem('jti',action.payload.data.jti);
                return {...state, isFetching: false, isAuthenticated:action.payload.data.adminValidated, jti:action.payload.data.jti}
            case LOGOUT_REQUEST:
                let removeAdminToken = localStorage.removeItem('adminValidated')
                let removeAccessToken = localStorage.removeItem('jti');
                return {...state, isFetching:true, profile: {}}
            case LOGOUT_SUCCESS:
                return {...state, isFetching: false, isAuthenticated: false}    
            default:
                return state
        }
  }