  
import {REQUESTPDFDATA,GETPDFDATA} from '../actions/index';


export default function pdfData(state = [], action){
    switch(action.type){
        case REQUESTPDFDATA:
            state = Object.assign({}, state, {status: "waiting"});
            // state = {data:state, status: "waiting"};
            return state;
        case GETPDFDATA:
            state = Object.assign({}, state, {data: [action.payload], status: "received"});
            // state = {data: action.payload, status: "received"};
            return state;
    }
    return state;
}