import axios from 'axios';


/* Update Root URL in prod */
const ROOT_URL = `https://gasb.udot.utah.gov`;
// const ROOT_URL = `https://gasb.dev.utah.gov`;
// const ROOT_URL = `http://localhost:8080`

const PDF_URL = `${ROOT_URL}/api/gasb/pdfs`;
const GASBLIST_URL = `${ROOT_URL}/api/gasb/gasb-list`;
const LOGIN_URL = `${ROOT_URL}/api/gasb/v2/auth/google`;
const LOGOUT_URL = `${ROOT_URL}/api/gasb/v2/auth/google/logout`;

export const FORMSUBMITTED = 'FORMSUBMITTED';
export const FORMSUBMISSIONSUCCESS = 'FORMSUBMISSIONREPONSE';
export const FORMSUBMISSIONERROR ='FORMSUBMISSIONERROR';
export const REQUESTPDFDATA = 'REQUESTPDFDATA';
export const GETPDFDATA = 'GETPDFDATA';
export const REQUESTLISTDATA = 'REQUESTLISTDATA';
export const GETLISTDATA = 'GETLISTDATA';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const UPDATETABLEDATA = 'UPDATETABLEDATA';
export const NEWTABLEDATA = 'NEWTABLEDATA'

export function formsubmit(data){
        return axios.post(`${ROOT_URL}/api/gasb/gasb-form`,data)
}

export function getpdfdata() {
    return function(dispatch){
        dispatch({type:REQUESTPDFDATA});
        axios.get(`${PDF_URL}`)
                .then(response => dispatch({
                    type:GETPDFDATA,
                    payload:response.data
                }))
                .catch(error => console.log(error))
    }
}

export function getlistdata() {
    return function(dispatch){
        dispatch({type:REQUESTLISTDATA});
        axios.get(`${GASBLIST_URL}`)
                .then(response => dispatch({
                    type:GETLISTDATA,
                    payload:response.data
                }))
                .catch(error => console.log(error))
    }
}

export function authadminlogin(data) {
    return function(dispatch){
        dispatch({type:LOGIN_REQUEST});
        axios.post(`${LOGIN_URL}`,data)
        .then(response => dispatch({
            type:LOGIN_SUCCESS,
            payload:response
        }))
        .catch(error => console.log(error))
    }
}

export function authadminlogout(data) {
    return function(dispatch){
        dispatch({type:LOGOUT_REQUEST});
        axios.post(`${LOGOUT_URL}`,data)
        .then(response => dispatch({
            type:LOGOUT_SUCCESS,
            payload:response
        }))
        .catch(error => console.log(error))
    }
}

export function updatetabledata(data) {
    axios.post(`${ROOT_URL}/api/gasb/update-table`,data)   
        return{
            type:UPDATETABLEDATA,
            payload:'Data update submitted'
    }
}

export function updatestructuredata(data) {
    axios.post(`${ROOT_URL}/api/gasb/update-structures`,data)   
        return{
            type:UPDATETABLEDATA,
            payload:'Data update submitted'
    }
}

