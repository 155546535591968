import React, { useState } from "react";
import {connect} from 'react-redux'
import MUIDataTable from "mui-datatables";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: '100%',
  }
});


function ListTableElement({statetree}) {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [responsive, setResponsive] = useState("standard");
  const [tableBodyHeight, setTableBodyHeight] = React.useState("100%");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = React.useState("100%");

  const columns = [
    {
      name: "pdf_name",
      label: "File Name"
    },
    {
      name: "url",
      label: "PDF File URL",
      options: { 
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <a href={value} target="_blank" rel="noreferrer">{value}</a>
          )}
      }
    },
    {
      name: "created_date",
      label: "Created Date"
    },
    {
      name: "modified_date",
      label: "Modified Date"
    },
  ];

  const options = {
    fixedHeader: true,
    filter: false,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [5,10,20,50,100],
    responsive:setResponsive,
    selectableRows:"none",
    jumpToPage:true,
    tableBodyHeight,
    tableBodyMaxHeight,
  };


  let arr = statetree.pdfData.data[0];
  var dataset = arr.slice(0,arr.length);

  return (
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
        <MUIDataTable
          title={
            <Typography variant="h6">
              GASB PDFS
            </Typography>
          }
          data={dataset}
          columns={columns}
          options={options}
          />

        </TableContainer>
      </Paper>
  );
}

const mapStateToProps = state => ({
    statetree:state
  });
  
  
  const PDFDataTable = connect(mapStateToProps)(ListTableElement);
  
  export default PDFDataTable;
  