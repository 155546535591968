import React from 'react'

function DropdownOption(props) {
  return(
    <div className="gs-dd-cont">
      <label className="dropdownlabel">{props.dropdownlabel}</label>
      <select name={props.name} id="selectList" onChange={props.onChange}>
        <option value=""></option>
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
    </div>
  )
}

export default DropdownOption
