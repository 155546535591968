import React from 'react'

function CustomDropdown(props) {
  return(
    <div className="gs-dd-cont">
      <label className="dropdownlabel">{props.dropdownlabel}</label>
      <select name={props.name} id="selectList" onChange={props.onChange}>
        <option value=""></option>
        <option value="Partial">Partial</option>
        <option value="Entire">Entire</option>
      </select>
    </div>
  )
}

export default CustomDropdown
