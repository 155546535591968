  
import {REQUESTLISTDATA,GETLISTDATA} from '../actions/index';

export default function listData(state = [], action){
    switch(action.type){
        case REQUESTLISTDATA:
            state = Object.assign({}, state, {status: "waiting"});
            return state;
        case GETLISTDATA:
            state = Object.assign({}, state, {data: [action.payload], status: "received"});
            return state;
    }
    return state;
}