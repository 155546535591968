import {createStore, applyMiddleware, compose} from 'redux';
import rootReducer from './reducers/root_reducer';
// import logger from 'redux-logger';
import thunk from 'redux-thunk';

export default function configureStore(initialState){
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(
        rootReducer, 
        initialState, 
        composeEnhancers(applyMiddleware(thunk))
    );
    
    return store
}