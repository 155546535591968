import React, { Component } from 'react'
import FormMenubar from '../components/formmenubar'
import HoverButton from '../components/hoverbutton'
import {Link} from 'react-router-dom'



class Home extends Component {

    render() {
        return (
            <div className="gs-bg-cont">
                    <FormMenubar/>
                    <div className="cont-disc">
                        <Link to="/gasb-form">
                            <HoverButton  hbutton="OPEN GASB FORM"/>
                        </Link>
                        <p className="gs-disc">Click this button to open a new GASB Form. Once you have submitted the form, you will be redirected to the reports page where your completed form sits. After a few seconds, your form will automatically be converted to a PDF, which will then prompt you to download the PDF. Optionally, you may also open your list item, which will show you a display version of the form you filled out. From here, there will be a button at the top named "Convert to PDF". Clicking this will convert and prompt you to download.</p>
                </div>
            </div>
        )
    }
}

export default Home;

