import React, { useState } from 'react'
import axios from 'axios'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {withRouter, Link, useHistory } from 'react-router-dom'
import {formsubmit} from '../actions/index'
import { Formik, Field, Form, ErrorMessage, FieldArray, useFormik } from 'formik';

import { Container, Button, Grid, CircularProgress, Box } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import InputLabelComponent from '../components/inputlabel';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveIcon from '@material-ui/icons/Remove';
import IconButton from '@material-ui/core/IconButton';
import PercentageField from '../components/percentagefield';

import DropdownOption from '../components/dropdownoption';
import CustomDropdown from '../components/customdropdown';
import HoverButton from '../components/hoverbutton'
import CancelButton from '../components/cancelbtn'

/* Submit Form - update url for prod */
// const BASE_URL =`${process.env.REACT_APP_API_URL}/api/gasb`;
const BASE_URL =`https://gasb.udot.utah.gov/api/gasb`;
// const BASE_URL =`https://gasb.dev.utah.gov/api/gasb`;

const screenSize = () => {
  if (window.innerWidth > 700){
    return 6
  }
  else return 12
}

function valPin(value) {
  let error;
  if (!value) {
    error = 'Required';
  }
  return error;
}


const valEmail =(values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
  ) {
    errors.email = 'Invalid email address';
  }
  return errors;
}

const validateIndex = (index) => {
  if(index === 10){
    return <div className="form-validate-index">Maximum number of structures allowed is 10. Do not submit the form with more than 10! If more are needed, please send additional details to Clint Lesueur (clesueur@utah.gov).</div>
  }
}

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

function ProjectFormComponent(props) {

    const [initialState] = useState({ 
      pinnumber:'',
      projectnumber:'', 
      projectmanager:'', 
      residentengineer:'', 
      contractor:'',
      completion:'',
      expenses:'',
      capincrease:'',
      capacity:'',
      effincrease:'',
      efficiency:'',
      pavement:'',
      partial:'',
      posmilestart:'',
      posmileend:'',
      negmilestart:'',
      negmileend:'',
      twentyfeet:'', 
      email:'',
      emailcc:'', 
      structures:[] 
    });
    const [loading, setLoading] = useState(false)
    const [isSubmitting, setSubmitting] = useState(false);


    const formSubmit = async(values) => {
      setSubmitting(true);
      formsubmit(values);

      await sleep(1000);
        setTimeout(() => { 
          setSubmitting(false);
          props.history.push('/gasb-reports');
        }, 3500);
    }


        return (
            <Container maxWidth="md">
              <Formik initialValues={initialState} validate={valEmail} enableReinitialize={true} onSubmit={(values)=>formSubmit(values)}>
                {({values, setValues, setFieldValue, errors, touched, handleChange, handleSubmit, form}) => {
                    
                const onPinVerification = async() => {
                  const URL = `${BASE_URL}`;
                  const pinnumber = values.pinnumber;
                  setLoading(true);
                  await axios.get(`${URL}/pin-verification?pinnumber=${pinnumber}`).then(res => {
                    let projectdata = res.data;
                    setFieldValue('projectnumber', projectdata.projectnumber);
                    setFieldValue('projectmanager', projectdata.projectmanager);
                    setFieldValue('residentengineer', projectdata.residentengineer);
                    setFieldValue('contractor', projectdata.contractor);
                  }).catch(error => console.log(error))
                  setLoading(false);
                };

                const updateValue = (name, value) => {
                  return setFieldValue(name, value);
                }

                return (
                  <Form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                      
                      <Grid item xs={12} sm={6}>
                        <div className="gs-form-elm-1">
                          <InputLabelComponent fieldlabel="PIN Number *"/>
                          <Field component={TextField} name="pinnumber" variant="outlined" validate={valPin}/>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div className="gs-form-elm-2">
                        {!loading ? <Button variant="outlined" size="medium" color="primary" onClick={onPinVerification}>Pin Verification</Button> : <CircularProgress />}
                        </div>
                      </Grid>


                      <Grid item xs={12} >
                          <InputLabelComponent fieldlabel="Project Number *"/>
                          <Field component={TextField} value={values.projectnumber} name="projectnumber" variant="outlined" />
                      </Grid>
                      <Grid item xs={12} >
                      <Grid sm={3} >
                        <InputLabelComponent fieldlabel="Project Manager *"/>
                        <Field component={TextField} name="projectmanager" variant="outlined"/>
                      </Grid>
                      </Grid>
                      <Grid item xs={12} >
                        <Grid sm={3} >
                          <InputLabelComponent fieldlabel="Resident Engineer *"/>
                          <Field component={TextField} name="residentengineer" variant="outlined"/>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} >
                        <Grid sm={3} >
                          <InputLabelComponent fieldlabel="Contractor *"/>
                          <Field component={TextField} name="contractor" variant="outlined"/>
                        </Grid>
                      </Grid>
                      
                      <Grid item xs={12} >
                          <InputLabelComponent fieldlabel="Is the project Substantially complete?"/>
                          <Field as={DropdownOption} name="completion" onChange={(e)=>updateValue(e.target.name,e.target.value)}/>
                      </Grid>

                      <Grid item xs={12} >
                          <InputLabelComponent fieldlabel="Are total expenses over $1,000,000?"/>
                          <Field as={DropdownOption} name="expenses" onChange={(e)=>updateValue(e.target.name,e.target.value)}/>
                      </Grid>

                      <Grid item xs={12} >
                          <InputLabelComponent fieldlabel="Was Capacity and/or Efficiency Increased:"/>
                          <InputLabelComponent fieldlabel="(Increase in Traffic Flow or the Reduction of delays)"/>
                          <Field as={DropdownOption} name="capincrease" onChange={(e)=>updateValue(e.target.name,e.target.value)}/>
                      </Grid>
                      
                      <Grid item xs={12} >
                          <InputLabelComponent fieldlabel="If yes, provide a professional estimate on the % of project cost attributed to the increase in Capacity and/or Efficiency:"/>
                          <Field as={PercentageField} name="capacity" handleChange={(e)=>updateValue(e.target.name, e.target.value)}/>
                      </Grid>
                                      
                      <Grid item xs={12} >
                          <InputLabelComponent fieldlabel="Did the contractor remove and replace the pavement?"/>
                          <Field as={DropdownOption} name="pavement" onChange={(e)=>updateValue(e.target.name,e.target.value)}/>
                      </Grid>
                                      
                      <Grid item xs={12} >
                          <InputLabelComponent fieldlabel="Partial or Entire?"/>
                          <Field as={CustomDropdown} name="partial" onChange={(e)=>updateValue(e.target.name,e.target.value)}/>
                      </Grid>

                      <Grid item xs={12} >
                          <InputLabelComponent fieldlabel="-If yes, what are the beginning and ending mileposts for the replaced section(s)? If both directions were replaced, please give the mileposts for both sides."/>
                      </Grid>
                      
                      <Grid item xs={12} >
                          <InputLabelComponent fieldlabel="Positive Direction Beginning Mile Post"/>
                          <Field component={TextField} name="posmilestart" variant="outlined"/>
                      </Grid>
                                      
                      <Grid item xs={12} >
                          <InputLabelComponent fieldlabel="Positive Direction Ending Mile Post"/>
                          <Field component={TextField} name="posmileend" variant="outlined"/>
                      </Grid>
                                      
                      <Grid item xs={12} >
                          <InputLabelComponent fieldlabel="Negative Direction Beginning Mile Post"/>
                          <Field component={TextField} name="negmilestart" variant="outlined"/>
                      </Grid>
                                      
                      <Grid item xs={12} >
                          <InputLabelComponent fieldlabel="Negative Direction Ending Mile Post"/>
                          <Field component={TextField} name="negmileend" variant="outlined"/>
                      </Grid>
                                                      
                      <Grid item xs={12} >
                          <InputLabelComponent fieldlabel="Does the contract include structures that span 20 feet or more?"/>
                          <Field as={DropdownOption} name="twentyfeet" onChange={(e)=>updateValue(e.target.name,e.target.value)}/>
                      </Grid>

                    </Grid>

                    <br/>
                    <Grid item xs={12} >
                    <p style={{fontSize:"12px", marginBottom:"20px"}}><i>Add or remove structures. Maximum limit is 10 structures, please send additional structure details to Clint Lesueur (clesueur@utah.gov).</i></p>
                    <FieldArray name="structures">
                        {fieldArrayProps => {
                          const {push, remove, form} = fieldArrayProps
                          const {values} = form
                          const {structures} = values
                          {console.log('struct:', structures)}
                          return ( 
                            <div className="dyn-formfield-cont">
                              {structures.map((structure, index) => (
                                <div key={index}>
                                  <Grid container spacing={1}>
                                    <Grid item xs={screenSize}>
                                      <InputLabelComponent fieldlabel={`Enter Structure ${index+1} #:`}/>
                                      <Field
                                          component={TextField}
                                          name={`structures.${index}.structure`}
                                          variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item xs={screenSize}>
                                      <InputLabelComponent fieldlabel="Structure Cost:"/>
                                      <Field
                                          component={TextField}
                                          name={`structures.${index}.cost`}
                                          variant="outlined"
                                      />
                                      </Grid>
                                      <Grid item xs={screenSize}>
                                      <InputLabelComponent fieldlabel="Replaced Structure:"/>
                                      <Field
                                          component={TextField}
                                          name={`structures.${index}.replacement`}
                                          variant="outlined"
                                      />
                                      </Grid>
                                      <Grid item xs={screenSize}>
                                      <InputLabelComponent fieldlabel="New Or Replacement?"/>
                                      <Field
                                          component={TextField}
                                          name={`structures.${index}.new`}
                                          variant="outlined"
                                      />
                                      </Grid>
                                      <Grid item xs={screenSize}>
                                      <InputLabelComponent fieldlabel="If new was capacity or efficiency increased?"/>
                                      <Field
                                          component={TextField}
                                          name={`structures.${index}.efficiency`}
                                          variant="outlined"
                                      />
                                      </Grid>
                                    <IconButton type='button' onClick={()=>remove(index)}> <DeleteIcon /> </IconButton>
                                    {validateIndex(index)}
                                  </Grid>
                                </div>
                              ))}
                              
                              <IconButton type='button' onClick={()=>push({structure: '', cost: '', replacement: '', efficiency: '', new: ''})}> <AddIcon /> </IconButton>
                              {window.innerWidth > 700 ? <IconButton type='button' onClick={()=>remove(values.structures)}> <RemoveIcon /> </IconButton> : null}
                            </div>
                          )}}
                    </FieldArray>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={12} >
                            <InputLabelComponent fieldlabel="Email"/>
                            <Field component={TextField} name="email"  variant="outlined"/> 
                      </Grid>

                      <Grid item xs={12} >
                            <InputLabelComponent fieldlabel="Email CC"/>
                            <Field component={TextField} name="emailcc"  variant="outlined"/> 
                      </Grid>
                    

                    </Grid>
                    <Box m={6} />
                          { isSubmitting ?
                              <div><CircularProgress/> <br/>Form Is Submitting ....</div> : 
                              <Grid container spacing={3}>   
                                <Grid item xs={12} sm={3}><Link to="/"><CancelButton cbutton="Cancel"/></Link></Grid> 
                                <Grid item xs={12} sm={3}>
                                <HoverButton hbutton="Submit" type="submit" value="submit" onClick={()=>!values.pinnumber || !values.email ? errors.pinnumber&&errors.email : handleSubmit}/> 
                                </Grid>
                              </Grid>
                              
                          }
                  </Form>
                )}}
              </Formik>
            </Container>
        )
    }

const mapDispatchToProps = (dispatch) => {
  return {...bindActionCreators({formsubmit}, dispatch)};
 }

 const mapStateToProps = (state) => {
  const { status, message, error } = state.auth
  return {
      status,
      message,
      error
  }
}

const ProjectFormv2 = withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectFormComponent));

export default ProjectFormv2;