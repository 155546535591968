import React, { Component } from 'react';
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {getpdfdata} from '../actions/index'
import FormMenubar from '../components/formmenubar'
// import PDFTable from '../components/pdftable'
import PDFDataTable from '../components/pdfdatatable'

export class GASBPDFTable extends Component {

  state = {
      pdfdata:false,
      status:"waiting",
      display:false
  }

  componentDidMount() {
    this.props.getpdfdata();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.status !== this.props.statetree.pdfData.status) {
      if(this.props.statetree.pdfData.status === "received"){
          return this.setState({status:this.props.statetree.pdfData.status, display:true})
      }
    }
  }
  
  render() {
    
    return (
      <div>
        <div className="gs-bg-cont-rpt">
          <div className="gs-bg-rpt-menu">
            <FormMenubar/>
          </div>
          <div className="gs-bg-rpt-table">
          {/* {this.state.display && <PDFTable/>} */}
          {this.state.display && <PDFDataTable/>}
          </div>
        </div>
    </div>
  )}
}


const mapDispatchToProps = (dispatch) => {
  return {...bindActionCreators({getpdfdata}, dispatch)};
 }

const mapStateToProps = state => ({
  statetree:state
});


const GASBPDF = connect(mapStateToProps, mapDispatchToProps)(GASBPDFTable);

export default GASBPDF;