import {FORMSUBMITTED,FORMSUBMISSIONSUCCESS, FORMSUBMISSIONERROR} from '../actions/index';

export default function formStatus(state = {status:'',message:''}, action){
    switch(action.type){
        case FORMSUBMITTED:
            console.log('request:',action)
            state = {...state, status: "waiting"};
            // console.log('request:',state)
            return state;
        case FORMSUBMISSIONSUCCESS:
            console.log('request:',action)
            state = {...state, status: "received", message: [action.payload]};
            // console.log("get:",state)
            return state;
        case FORMSUBMISSIONERROR:
            console.log('request:',action);
            state = {...state, status: "error", message: [action.payload]};
    }
    return state;
}